import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Transition from 'components/layout/Transition'
import Body from 'components/Blocks/Body'
// import Sidebar from 'components/Blocks/Sidebar'
import { Heading, Text, Box, Link, Flex, Grid, GridItem } from '@chakra-ui/react'
import { HeroPage } from 'components/Hero'

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = useRef({})
  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id)

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id)
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )
        setActiveId(sortedVisibleHeadings[0].target.id)
      }
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -40% 0px'
    })

    const headingElements = Array.from(document.querySelectorAll('h2'))

    headingElements.forEach((element) => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId])
}


const TableOfContents = ({ value }) => {

  const [activeId, setActiveId] = useState()
  useIntersectionObserver(setActiveId)

  const headings = value && value
    // filter out everything that's not a text block and is not an h2
    .filter(({ _type, style }) => _type === 'block' && style === 'h2')

  return <Flex
    id='toc'
    mt={'0 !important'}
    position={{ base: 'relative', lg: 'sticky' }}
    zIndex={'sticky'}
    alignSelf={'start'}
    top={{ base: 0, lg: '180px' }}
    ml={{ base: 0, lg: '2rem' }}
    direction='column'
    gridColumn={{ base: '1/8', lg: '7/9', xl: '2/3' }}
  >
    {
      headings.map(({ _key, children }) => {
        const { text } = children[0]
        return <Link
          key={_key}
          href={`#${_key}`}
          sx={{
            'span': {
              // textDecoration: _key === activeId ? 'underline' : 'none'
            }
          }}
          _hover={{ textDecoration: 'underline' }}
          _before={{
            content: _key === activeId ? '"→"' : '""',
            // display: 'inline-flex',
            position: 'absolute',
            marginLeft: '-20px'
          }}
          onClick={(e) => {
            e.preventDefault()
            document.getElementById(`${_key}`)?.scrollIntoView({ behavior: 'smooth' })
          }}
        ><span>{text}</span></Link>
      })
    }
    </Flex>
  }


const ArticlePage = ({ data }) => {
  const { article } = data
  const { showHero, title, hero } = article
  return (
    <Transition>
      { showHero && hero ? <HeroPage value={hero} /> :
        <Grid as='header'><GridItem gridColumn={{ base: '3/9', xl: '4/8' }} gridRow={'4/7'}><Heading as='h1' size='2xl' variant='caps' lineHeight='shorter'><Text as={'span'} variant={'case'}>→ </Text>{title}</Heading></GridItem></Grid>
      }
      <Grid pt={'10rem'} mb={'5rem'} px={'5rem'} gap={'2rem'} gridRowGap={0} templateColumns={['repeat(8, 1fr)', null]}
        sx={{
          '> div + div': {
            mt: 100
          },
          '> p + p': {
            mt: 2
          },
          '> div + h2': {
            mt: 100
          },
          '> h2 + p': {
            mt: 50
          },
          '> p + h2': {
            mt: 100
          },
          '> p + aside': {
            mt: 100
          },
          '> aside + p': {
            mt: 50
          },
          '> div + p, > p + div, > aside + div': {
            mt: 100
          },
          '> #toc + h2': {
            mt: 0
          }
        }}>
        <Box gridColumn={{ base: '1/9', lg: '1/7', xl: '3/7' }} />
        <TableOfContents value={data.article._rawBody} />
        <Body value={data.article._rawBody} />
        {/*<Sidebar value={data.article._rawBody} />*/}
      </Grid>
    </Transition>
  )
}

export default ArticlePage

export const query = graphql`
query($id: String!) {
  article: sanityArticleEditorial(id: { eq: $id }) {
    id
    title
    showHero
    hero {
      title
      subtitle
      colorTheme {
        background {
          hex
        }
      }
      content {
        ... on SanityImage {
          _type
          ...ImageWithHotspot
        }
        ... on SanityModuleVideo {
          _type
          url
          isBackground
          _rawCaption
        }
      }
    }
    _rawBody(resolveReferences: {maxDepth: 10})
  }
}`
